import anime from 'animejs'
import config from '../config'
import signals from '../store/signals'
import store from '../store/store'
import Plyr from 'plyr'

class Hero {
  constructor (props) {
    if (!props.el) return

    this.el = props.el
    this.videoIsPlaying = false

    this.initParams()
    this.bind()
  }

  initParams () {
    this.arrow = this.el.querySelector('.bloc-hero-arrow')
    this.poster = this.el.querySelector('.bloc-hero-poster')

    this.hasVideo = this.el.classList.contains('has-video')
    this.videoIsExternal = this.el.classList.contains('has-external-video')

    if (this.hasVideo) {
      this.video = this.el.querySelector('.video')
      this.videoContainer = this.el.querySelector('.video-container')
      this.close = this.el.querySelector('.close')
    } 

    if (this.videoIsExternal) this.setUpPlyr()
  }

  setUpPlyr () {
    this.player = new Plyr(this.video, {
      controls: ['play-large', 'play', 'progress', 'current-time', 'mute', 'volume', 'captions', 'settings', 'pip', 'fullscreen'],
    })
  }

  bind () {
    this.arrow.addEventListener('click', this.scrollDown)
    
    if (this.hasVideo) this.poster.addEventListener('click', this.playVideo)
    if (this.hasVideo) this.close.addEventListener('click', this.stopVideo)

    // if (this.video) this.video.addEventListener('click', this.tooglePlay)
  }

  unbind () {
    this.arrow.removeEventListener('click', this.scrollDown)

    if (this.hasVideo) this.poster.removeEventListener('click', this.playVideo)
    if (this.hasVideo) this.close.removeEventListener('click', this.stopVideo)

    if (this.videoIsExternal) store.resize.unlisten(this.resizeFullWidthAndHeight)

    // if (this.video) this.video.removeEventListener('click', this.tooglePlay)
  }

  scrollDown = () => {
    window.scrollTo({ top: this.el.offsetHeight, behavior: 'smooth' })
  }

  tooglePlay = () => {
    if (this.videoIsPlaying) {
      this.stopVideo()
    } else {
      this.playVideo()
    }
  }

  stopVideo = () => {
    this.videoIsPlaying = false

    document.body.classList.remove('no-scroll')
    this.el.classList.remove('video-playing')
    if (this.videoIsExternal) this.player.pause()
    else this.video.pause()
    
  }

  playVideo = () => {
    this.videoIsPlaying = true

    document.body.classList.add('no-scroll')
    this.el.classList.add('video-playing')

    setTimeout(() => {
      if (this.videoIsExternal) this.player.play()
      else this.video.play()
    }, 600)
  }

  destroy () {
    this.unbind()
    if (this.player) this.player.destroy()
  }
}

let heroDom
let heros = []

function init () {
  heroDom = [...document.querySelectorAll('.js-hero')]

  heroDom.forEach((el) => {
    heros.push(new Hero({ el: el }))
  })
}

function unmount () {
  heros.forEach((hero) => {
    hero.destroy()
  })
}

export default {
  init,
  unmount,
  Hero,
}
