
import { Signal } from './state'

const signals = {
  // common
  resizeEnd: new Signal(),
  mousewheel: new Signal(),
  mousewheelEnd: new Signal(),
  scrollToTarget: new Signal(),
  bindCursorEls: new Signal(),
  updateLocomotive: new Signal(),
  resetLinksTransition: new Signal(),
  currentSectionUpdate: new Signal(),
  askNavigateBack: new Signal(),
  stopLocomotive: new Signal(),
  restartLocomotive: new Signal(),
  startTransition: new Signal(),
  transitionReady: new Signal(),
  intersectStart: new Signal(),
  intersectStop: new Signal(),
  remove3DAnimation: new Signal(),
  removeAll3D: new Signal(),
  itemLoaded: new Signal(),

  // scroll
  resetScrollama: new Signal(),
  startingSection: new Signal(),
  progressUpdate: new Signal(),
  endingSection: new Signal(),
  startingSection2: new Signal(),
  progressUpdate2: new Signal(),
  endingSection2: new Signal(),
}

export default signals
