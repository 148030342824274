import signals from '../store/signals'
import store from '../store/store'

class ScrollShowController {
  constructor (props) {
    this.els = []

    this.checkSectionStartScrollStep1 = this.checkSectionStartScrollStep1.bind(this)
    this.checkSectionStartScrollStep2 = this.checkSectionStartScrollStep2.bind(this)
    this.initParams = this.initParams.bind(this)
    this.resetElements = this.resetElements.bind(this)
    this.securityCheck = this.securityCheck.bind(this)

    this.initParams()
    this.bind()
  }

  initParams () {
    setTimeout(() => {
      this.els = [...document.querySelectorAll('.js-scroll-show')]
      this.securityCheck()
    }, 700)
  }

  bind () {
    signals.startingSection.listen(this.checkSectionStartScrollStep1)
    signals.startingSection2.listen(this.checkSectionStartScrollStep2)
    signals.resetScrollama.listen(this.resetElements)
  }

  unbind () {
    signals.startingSection.unlisten(this.checkSectionStartScrollStep1)
    signals.startingSection2.unlisten(this.checkSectionStartScrollStep2)
    signals.resetScrollama.unlisten(this.resetElements)
  }

  checkSectionStartScrollStep1 (response) {
    this.els.forEach((el) => {
      const scrollStepElement = el.closest('.scroll-step')
      if (!scrollStepElement) return
      const scrollIndex = scrollStepElement.dataset.scrollamaIndex
      if (response.index == scrollIndex) this.fire(el)
    })
  }

  checkSectionStartScrollStep2 (response) {
    this.els.forEach((el) => {
      const scrollStepElement = el.closest('.scroll-step-2')
      if (!scrollStepElement) return
      const scrollIndex = scrollStepElement.dataset.scrollamaIndex
      if (response.index == scrollIndex) this.fire(el)
    })
  }

  securityCheck () {
    this.els.forEach((el) => {
      if (el.offsetTop < store.resize.get().height - 100) {
        this.fire(el)
      }
    })
  }

  resetElements () {
    this.initParams()
  }

  fire (el) {
    el.classList.add('visible')
  }
}

export default new ScrollShowController()
