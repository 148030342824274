import Main from './site/Main'

window.onload = () => {
  if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js')
        .catch(registrationError => {
          console.log('SW registration failed: ', registrationError);
        });
  } else {
    console.log('Service worker is not active on your navigator');
  }

  new Main(document.body)
}
