import mapValues from 'lodash/mapValues'
import Router from './Router'
import defer from 'lodash/defer'
import signals from '../store/signals'
import store from '../store/store'
import config from '../config'

const CROSS_TRANSITION = false
const LOAD_BEFORE_HIDE = false

class PageManager {  
  constructor (container, pageSelector, routes) {
    this.state = { transitioning: false, loading: false, previous: false, next: false }
    this.page = { current: null, previous: null }
    this.container = container
    this.pageSelector = pageSelector
    this.initializeRoutes(routes)

    // binding 
    this.pageHidden = this.pageHidden.bind(this)
    this.pageShown = this.pageShown.bind(this)
    this.transitionReady = this.transitionReady.bind(this)
  }

  initializePage (pathName, PageClass) {
    this.title = document.querySelector('title')

    this.bodyClassName = document.body.className
    if (this.bodyClassName.indexOf('error404') > -1) pathName = '404'
    this.page.current = this.createPage(
      this.extractPage(this.container),
      PageClass
    )
    this.page.current.prepare(false)
    this.page.current.askShow(false)
    Router.updatePageLinks()
  }

  initializeRoutes (routes) {
    const cb = PageClass => () => this.navigateTo(Router.path(), PageClass)
    Router.on(mapValues(routes, v => cb(v)))
    defer(() => Router.resolve())
  }

  navigateTo (pathName, PageClass) {
    if (!this.page.current) return this.initializePage(...arguments)

    if (this.state.transitioning || this.state.loading) {
      Router.pause()
      Router.lastRouteResolved().url = this.pathName
      Router.navigate(this.pathName, true)
      Router.resume()

      this.waitingForPage = pathName
      return
    }

    this.pathName = pathName
    this.state.next = PageClass.pname

    if (!LOAD_BEFORE_HIDE) {
      if (this.page.current) this.page.previous = this.page.current

      if (this.page.previous) this.hidePage()
    }

    this.load(pathName, PageClass)
  }

  load (pathName, PageClass) {
    if (this.xhr) {
      this.xhr.onload = this.xhr.onerror = null
      this.xhr.abort()
    }
    this.state.loading = true
    this.xhr = new XMLHttpRequest()
    this.xhr.open('GET', pathName, true)
    this.xhr.responseType = 'document'
    this.xhr.onload = () => this.pageLoaded(pathName, PageClass)
    this.xhr.send()
  }

  pageLoaded (pathName, PageClass, event) {
    this.state.loading = false

    /* eslint-disable no-console */
    if (this.xhr.status < 200 || this.xhr.status > 404)
      return console.error('error loading')
    /* eslint-enable no-console */

    const page = this.xhr.response
    this.bodyClassName = page.body.className
    this.title.textContent = page.title
    if (this.bodyClassName.indexOf('error404') > -1) pathName = '404'
    const el = this.extractPage(page.body)
    this.preparePage(el, PageClass)
    this.xhr = null
  }

  preparePage (el, PageClass) {
    if (LOAD_BEFORE_HIDE && this.page.current)
      this.page.previous = this.page.current

    this.state.transitioning = true
    this.page.current = this.createPage(el, PageClass)

    if (this.page.previous) {
      if (LOAD_BEFORE_HIDE) this.hidePage()
      else if (this.page.previous.state.hidden) this.pageHidden()
      if (CROSS_TRANSITION) this.showPage()
    } else this.showPage()
  }

  showPage () {
    const previousPage = this.state.previous
    this.page.current.prepare(previousPage)
    this.container.appendChild(this.page.current.el)
    document.body.className = this.bodyClassName

    defer(() => {
      Router.updatePageLinks()
      this.page.current.askShow(previousPage).then(this.pageShown)
    })
  }

  transitionComplete () {
    if (!this.state.transitioning) return
    this.state.transitioning = false
  }

  pageShown () {
    if (CROSS_TRANSITION && this.page.previous) this.removePage()
    this.transitionComplete()
    signals.updateLocomotive.dispatch()
    signals.restartLocomotive.dispatch()
    signals.resetScrollama.dispatch()

    if (this.waitingForPage) {
      Router.navigate(this.waitingForPage, true)
      this.waitingForPage = null
    }
  }

  hidePage () {
    signals.startTransition.dispatch()
    signals.transitionReady.listen(this.transitionReady)
  }

  transitionReady () {
    signals.transitionReady.unlisten(this.transitionReady)
    const nextPage = this.state.next
    this.state.previous = this.page.previous.name()
    this.page.previous.askHide(nextPage).then(this.pageHidden)
  }

  pageHidden () {
    if (!LOAD_BEFORE_HIDE && this.state.loading) return

    if (CROSS_TRANSITION) {
      if (!this.page.current || this.page.current.state.shown) {
        this.removePage()
        this.transitionComplete()
      }
    } else {
      this.removePage()
      if (LOAD_BEFORE_HIDE || this.page.current) this.showPage()
    }
  }

  removePage () {
    this.container.removeChild(this.page.previous.el)
    this.page.previous.flush()
    this.page.previous = null
    document.body.scrollTop = 0
    signals.stopLocomotive.dispatch()
    signals.scrollToTarget.dispatch({ target : 0, offset: 0, duration: 0})
    signals.remove3DAnimation.dispatch()
    signals.removeAll3D.dispatch()
  }

  extractPage (el) {
    return el.querySelector(this.pageSelector)
  }

  createPage (el, PageClass) {
    return new PageClass(el)
  }
}

export default PageManager
