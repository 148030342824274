import anime from 'animejs'
import config from '../config'
import signals from '../store/signals'
import store from '../store/store'

class Header {
  constructor (props) {
    if (!props.el) return

    this.el = props.el

    this.menuMobileIsOpen = false
    this.isHome = document.body.classList.contains('template-home')

    this.initParams()
    this.bind()
    this.resize()
  }

  initParams () {
    this.firstLink = this.el.querySelector('.link')
    this.mobileLink = this.el.querySelector('.home-mobile-link')
    this.menuBurger = this.el.querySelector('.menu-burger')

    this.mobileLinkContainer = this.el.querySelector('.home-mobile-link-container')

    if (this.isHome && !config.small) this.el.classList.add('visible')
  }

  bind () {
    setTimeout(() => {
      if (!config.small && this.isHome) {
        window.addEventListener('mousewheel', this.onScroll)
      } 
    }, 1000)

    if (this.isHome) this.mobileLink.addEventListener('click', this.onScroll)

    if (config.small) this.menuBurger.addEventListener('click', this.toggleMenuMobile)

    store.resize.listen(this.resize)
  }

  unbind () {
    window.removeEventListener('mousewheel', this.onScroll)
    this.menuBurger.removeEventListener('click', this.toggleMenuMobile)
    this.mobileLink.removeEventListener('click', this.onScroll)

    store.resize.unlisten(this.resize)
  }

  onScroll = () => {
    window.removeEventListener('mousewheel', this.onScroll)
    this.firstLink.click()
  }

  toggleMenuMobile = () => {
    if (this.menuMobileIsOpen) {
      document.body.classList.remove('no-scroll')
      this.menuMobileIsOpen = false
      this.el.classList.remove('menu-opened')
    } else {
      document.body.classList.add('no-scroll')
      this.menuMobileIsOpen = true
      this.el.classList.add('menu-opened')
    }
  }

  resize () {
    if (config.mobile && config.safari) {
      this.mobileLinkContainer.style.height = `${window.innerHeight - 20}px`
    }
  }


  destroy () {
    this.unbind()
  }
}

let headerDom
let headers = []

function init () {
  headerDom = [...document.querySelectorAll('.js-app-header')]

  headerDom.forEach((el) => {
    headers.push(new Header({ el: el }))
  })
}

function unmount () {
  headers.forEach((header) => {
    header.destroy()
  })
}

export default {
  init,
  unmount,
  Header,
}
