const $html = document.getElementsByTagName('html')[0];

let mobile;
let tablet;
let device;
let desktop;
let ie;
let safari9;
let safari;
let chrome;
let firefox;
let small;
let isHome;
let wide;

let Config;

function setUp () {
  if ($html.classList.contains('ie') || $html.classList.contains('ie11') || $html.classList.contains('edge')) {
    Config.ie = true;
  }

  if ($html.classList.contains('safari')) {
    Config.safari = true;
  }

  if ($html.classList.contains('chrome')) {
    Config.chrome = true;
  }

  if ($html.classList.contains('safari9')) {
    Config.safari9 = true;
  }

  if ($html.classList.contains('firefox')) {
    Config.firefox = true;
  }

  if ($html.classList.contains('mobile')) {
    Config.mobile = true;
    Config.device = true;
  } else if ($html.classList.contains('tablet')) {
    Config.tablet = true;
    Config.device = true;
  } else if ($html.classList.contains('desktop')) {
    Config.desktop = true;
  }

  if (document.body.classList.contains('template-home')) {
    Config.isHome = true;
  }

  Config.wide = window.innerWidth < 1200;
  Config.small = window.innerWidth < 800;
  Config.smaller = window.innerWidth < 480;
}

Config = {
  mobile,
  tablet,
  desktop,
  device,
  ie,
  safari,
  safari9,
  chrome,
  firefox,
  small,
  tabletSize: 1024,
  mobileSize: 480,
  minHeight: 650,
  setUp,
  isHome,
  wide,
}

export default Config;
