import store from '../store/store';
import config from '../config'

function Resize () {
  function init () {
    resize();
    window.addEventListener('resize', resize);
  }

  function remove () {
    window.removeEventListener('resize', resize);
  }

  function resize (e) {
    store.resize.set({ width: window.innerWidth, height: window.innerHeight });
    
    // eslint-disable-next-line
    if (!config.small && window.innerWidth <= 800) window.location.href = window.location.href
    // eslint-disable-next-line
    if (config.small && window.innerWidth >= 800) window.location.href = window.location.href

    config.wide = window.innerWidth <= 1200;
    config.small = window.innerWidth <= 800;
    config.smaller = window.innerWidth <= 480;
  }

  return {
    init,
    remove,
  };
}

export default Resize();
