import Page from '../../core/Page'

import Header from '../../components/header'
// import Footer from '../../components/footer'
// import CtaMail from '../../components/ctaMail'
// import LinesDetail from '../../components/linesDetail'
// import AnimesUp from '../../components/animesUp'
// import lazyload from '../../utils/lazyload'

class About extends Page {
  askShow (previousPage) {
    return new Promise((resolve, reject) => {
      this.show(() => {
        this.state.shown = true
        resolve()
      }, previousPage)
    })
  } 


  askHide (nextPage) {
    return new Promise((resolve, reject) => {
      this.hide(() => {
        this.state.hidden = true
        this.unmout()
        resolve()
      }, nextPage)
    })
  }

  show (callback, previousPage) {
    this.el.style.opacity = 1
    this.init()
    callback()
  }

  init () {
    // lazyload.init()
    Header.init()
    // CtaMail.init()
    // LinesDetail.init()
    // AnimesUp.init()
  }

  unmout () {
    // lazyload.remove()
    Header.unmount()
    // CtaMail.unmount()
    // LinesDetail.unmount()
    // AnimesUp.unmount()
  }
}

About.pname = 'About'

export default About
