import Page from '../../core/Page'

import Header from '../../components/header'
// import Footer from '../../components/footer'
// import Showreel from '../../components/showreel'
// import Project from '../../components/project'
// import LinesDetail from '../../components/linesDetail'
// import AnimesUp from '../../components/animesUp'
// import lazyload from '../../utils/lazyload'

class Contact extends Page {
  askShow (previousPage) {
    return new Promise((resolve, reject) => {
      this.show(() => {
        this.state.shown = true
        resolve()
      }, previousPage)
    })
  }

  askHide (nextPage) {
    return new Promise((resolve, reject) => {
      this.hide(() => {
        this.state.hidden = true
        this.unmout()
        resolve()
      }, nextPage)
    })
  }

  show (callback, previousPage) {
    this.el.style.opacity = 1
    this.init()
    callback()
  }

  hide (callback, nextPage) {
    this.el.style.opacity = 0
    callback()
  }

  init () {
    // lazyload.init()
    Header.init()
    // Footer.init()
    // Showreel.init()
    // Project.init()
    // LinesDetail.init()
    // AnimesUp.init()
  }

  unmout () {
    // lazyload.remove()
    Header.unmount()
    // Footer.unmount()
    // Showreel.unmount()
    // Project.unmount()
    // LinesDetail.unmount()
    // AnimesUp.unmount()
  }
}

Contact.pname = 'Contact'

export default Contact
