import { createStore } from './state'

const store = createStore({
  // Utils
  size: { w: 0, h: 0 },

  // global
  scroll: 0,
  mouse: [0, 0],
  resize: [0, 0],

  // transition
  cursorCbs: {},
  pageTranstionPurpose: 1000,
  pageTranstionState: 0,

  // locomotive
  locomotiveCbs: {},
  locomotiveProgress: 0,
  locomotiveParcours: 3000,
  locomotiveTotalScroll: 0,

  // 3D
  scene: null,
  sceneController: null,
  modelsController: null,
  sceneAnimationsController: null,
  sceneActive: '',
  denyCanvasAppending: false,
  sceneMouse: [0, 0],
})

export default store
