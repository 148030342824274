import Plyr from 'plyr'
import store from '../store/store'
import signals from '../store/signals'
import config from '../config'

class Showreel {
  constructor (props) {
    if (!props.el) return

    this.el = props.el

    this.resizeFullWidthAndHeight = this.resizeFullWidthAndHeight.bind(this)

    this.initParams()
    this.initPlyr()
    this.bind()
    this.resizeFullWidthAndHeight()
  }

  initParams () {
    this.videoContainer = this.el.querySelector('.video-container')
    this.video = this.el.querySelector('.video')

    if (!config.small) this.el.classList.add('visible')
  }

  initPlyr () {
    this.player = new Plyr(this.video, {
      controls: [],
      autoplay: true,
      loop: {
        active: true
      },
      muted: true,
      vimeo: { muted: true }
    })
    this.player.on('ready', this.play)
  }

  bind () {
    store.resize.listen(this.resizeFullWidthAndHeight)

    this.interval = setInterval(() => {
      this.player.volume = 0
    }, 100)
  }
  
  unbind () {
    store.resize.listen(this.resizeFullWidthAndHeight)
    clearInterval(this.interval)
  }

  play () {
    
  }

  resizeFullWidthAndHeight () {
    const backgroundHeight = window.innerHeight
    const backgroundWidth = window.innerWidth

    let ratio = (backgroundWidth / backgroundHeight) * 100
    ratio = Math.round(ratio)
    ratio = ratio / 100

    if (ratio < 1.77) {
      this.videoContainer.style.height = '100%'
      this.videoContainer.style.width = `${Math.round(backgroundHeight * 1.78)}px`
    } else {
      this.videoContainer.style.width = '100%'
      this.videoContainer.style.height = `${Math.round(backgroundWidth / 1.78)}px`
    }
  }

  destroy () {
    this.unbind()
    this.player.destroy()
  }
}

let showreelDom
let showreels = []

function init () {
  showreelDom = [...document.querySelectorAll('.js-showreel')]

  showreelDom.forEach((el) => {
    showreels.push(new Showreel({ el: el }))
  })
}

function unmount () {
  showreels.forEach((showreel) => {
    showreel.destroy()
  })
}


export default {
  init,
  unmount,
  Showreel,
}
