import anime from 'animejs'
import signals from '../store/signals'

class PageTransition {
  constructor (props) {
    if (!props.el) return

    this.el = props.el

    this.startTransition = this.startTransition.bind(this)
    this.onLinkClick = this.onLinkClick.bind(this)

    this.initParams()
    this.bind()
    this.bindExternal()
  }

  initParams () {
    this.firstBanner = this.el.querySelector('.page-transition__first-banner')

    this.appContainer = document.querySelector('.app-container')
    this.navigoLinks = [...document.querySelectorAll('[data-navigo]')]
  }

  bindExternal () {
    signals.startTransition.listen(this.startTransition)
  }

  unbindExternal () {
    signals.startTransition.unlisten(this.startTransition)
  }

  bind () {
    this.navigoLinks.forEach((el) => {
      el.addEventListener('click', this.onLinkClick)
    })
  }

  unbind () {
    this.navigoLinks.forEach((el) => {
      el.removeEventListener('click', this.onLinkClick)
    })
  }

  onLinkClick (event) {

  }

  startTransition (event) {
    anime({
      targets: this.appContainer,
      translateY: [0, '-100px'],
      duration: 600,
      easing: 'easeInOutQuad'
    })

    anime({
      targets: this.firstBanner,
      translateY: ['100%', 0],
      duration: 600,
      easing: 'easeInOutQuad',
      complete: () => {
        this.unbind()
        signals.transitionReady.dispatch()
        this.endTransition()
      }
    })
  }

  endTransition () {
    anime({
      targets: this.el,
      opacity: [1, 0],
      duration: 500,
      delay: 200,
      easing: 'easeOutQuad',
      complete: () => {
        this.firstBanner.removeAttribute('style')
        this.el.removeAttribute('style')
        this.initParams()
        this.bind()
      }
    })
  }

  destroy () {
    this.unbindExternal()
    this.unbind()
  }
}

let pageTransitionDom
let pageTransitions = []

function init () {
  pageTransitionDom = [...document.querySelectorAll('.js-page-transition')]

  pageTransitionDom.forEach((el) => {
    pageTransitions.push(new PageTransition({ el: el }))
  })
}

function unmount () {
  pageTransitions.forEach((pageTransition) => {
    pageTransition.destroy()
  })
}

export default {
  init,
  unmount,
  PageTransition,
}
